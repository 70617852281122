import {
    getHasOfferOrIncludedOrCompleted,
    type CurrentUserIguanaObject,
    getNotJoiningProgram,
    getIncludedOrGraduatedProgramInclusion,
} from 'Users';
import { getProgramSwitcherConfigs, type ProgramSwitcherProgramConfig } from 'ProgramSwitcher';
import { type CohortClass } from 'Cohorts';
import { type TranslationHelperClass } from 'Translation';
import { type DialogModal, type DialogModalAlertOptions } from 'DialogModal';
import { type SignOutHelperType } from 'Authentication';
import { StudentProfileFormConfigs } from 'FormConfigs';
import { targetBrandConfig } from 'AppBranding';
import { createOutsideSupportModal } from 'Support';
import { navigationHelper, NavigationSectionKey, type NavigationSectionConfig } from 'NavigationHelpers';

function launchProgramSwitcherModal(
    $injector: angular.auto.IInjectorService,
    programConfigs: ProgramSwitcherProgramConfig[],
) {
    const DialogModal = $injector.get<DialogModal>('DialogModal');
    const TranslationHelper = $injector.get<TranslationHelperClass>('TranslationHelper');
    const translationHelper = new TranslationHelper('settings.settings');

    const modalOptions: DialogModalAlertOptions = {
        content: '<program-switcher program-configs="programConfigs"></program-switcher>',
        classes: ['program-switcher'],
        title: translationHelper.get('switch_program'),
        hideCloseButton: false,
        scope: {
            programConfigs,
        },
        animated: true,
    };

    DialogModal.alert(modalOptions);
}

// eslint-disable-next-line max-lines-per-function
export default function navigationSectionConfigs(
    $injector: angular.auto.IInjectorService,
    currentUser: CurrentUserIguanaObject,
) {
    const Cohort: CohortClass = $injector.get('Cohort');
    const $location = $injector.get('$location');
    const SignOutHelper = $injector.get<SignOutHelperType>('SignOutHelper');

    const programConfigs = getProgramSwitcherConfigs($injector, currentUser);
    const brandConfig = targetBrandConfig(currentUser);
    const notJoiningProgram = getNotJoiningProgram(currentUser);
    const { loadFaqUrl, loadRoute } = navigationHelper();

    const TranslationHelper = $injector.get<TranslationHelperClass>('TranslationHelper');
    const supportTranslationHelper = new TranslationHelper('support.support');

    function goToSection(sectionName: string) {
        $location.search({});
        loadRoute(`/settings/${sectionName}`);
    }

    const applicationSectionLabelKey = () => {
        if ($location.path() === '/settings/select-program') {
            return 'apply_to_a_program';
        }

        if (currentUser.hasPendingProgramApplication) {
            return 'edit_application';
        }

        return 'apply_to_a_program';
    };

    const configs: NavigationSectionConfig[] = [
        {
            name: NavigationSectionKey.application,
            labelKey: applicationSectionLabelKey(),
            isActive: section =>
                section === NavigationSectionKey.application || section === NavigationSectionKey.select_program,
            shouldBeVisible: () =>
                currentUser.canCurrentlySubmitProgramApplication || currentUser.hasPendingProgramApplication,
            subsections: currentUser.relevantCohort?.applicationFormConfigs,
            onClick: forceSelectProgram => {
                let section =
                    brandConfig.supportsSelectProgram && currentUser.canCurrentlySubmitProgramApplication
                        ? NavigationSectionKey.select_program
                        : NavigationSectionKey.application;
                if (forceSelectProgram) section = NavigationSectionKey.select_program;
                goToSection(section);
            },
        },
        {
            name: NavigationSectionKey.application_status,
            labelKey: 'program_status',
            isActive: section => section === NavigationSectionKey.application_status,
            shouldBeVisible: () => {
                const declinedExecEdAdmissionOffer = notJoiningProgram && currentUser.relevantCohort?.isExecEd;

                if (
                    currentUser.relevantCohort?.supportsAdmissionRounds &&
                    !declinedExecEdAdmissionOffer &&
                    getHasOfferOrIncludedOrCompleted(currentUser)
                ) {
                    return true;
                }

                return false;
            },
            onClick: () => goToSection(NavigationSectionKey.application_status),
        },
        {
            name: NavigationSectionKey.switch_program,
            isActive: section => section === NavigationSectionKey.switch_program,
            shouldBeVisible: () => programConfigs.length > 1,
            onClick: () => launchProgramSwitcherModal($injector, programConfigs),
        },
        {
            name: NavigationSectionKey.select_program,
            shouldBeVisible: () => false,
            onClick: () => goToSection(NavigationSectionKey.select_program),
        },
        {
            name: NavigationSectionKey.account,
            isActive: section => section === NavigationSectionKey.account,
            shouldBeVisible: () => true,
            onClick: () => goToSection(NavigationSectionKey.account),
        },
        {
            name: NavigationSectionKey.my_profile,
            isActive: section => section === NavigationSectionKey.my_profile,
            shouldBeVisible: () => currentUser.hasEditCareerProfileAccess || currentUser.hasAdminAccess,
            subsections: StudentProfileFormConfigs,
            onClick: () => goToSection(NavigationSectionKey.my_profile),
        },
        {
            name: NavigationSectionKey.nominations,
            shouldBeVisible: () => currentUser.hasNominationsAccess,
            onClick: () => loadRoute('/nominations'),
        },
        {
            name: NavigationSectionKey.preferences,
            isActive: section => section === NavigationSectionKey.preferences,
            shouldBeVisible: () => true,
            onClick: () => goToSection(NavigationSectionKey.preferences),
        },
        {
            name: NavigationSectionKey.documents,
            isActive: section => section === NavigationSectionKey.documents,
            shouldBeVisible: () => {
                if (notJoiningProgram) return false;

                if (Cohort.supportsDocumentUpload(currentUser.programType) && currentUser.hasEverApplied) {
                    return true;
                }

                return false;
            },
            onClick: () => goToSection(NavigationSectionKey.documents),
        },
        {
            name: NavigationSectionKey.grades,
            isActive: section => section === NavigationSectionKey.grades,
            shouldBeVisible: () =>
                Cohort.isDegreeProgram(currentUser.programType) &&
                !!getIncludedOrGraduatedProgramInclusion(currentUser),
            onClick: () => goToSection(NavigationSectionKey.grades),
        },
        {
            name: NavigationSectionKey.faq,
            isActive: section => section === NavigationSectionKey.faq,
            shouldBeVisible: () => Cohort.isDegreeProgram(currentUser.programType),
            onClick: () => loadFaqUrl('/help', '_blank'),
        },
        {
            name: NavigationSectionKey.support,
            isActive: section => section === NavigationSectionKey.support,
            shouldBeVisible: () => true,
            onClick: () => createOutsideSupportModal($injector, supportTranslationHelper.get('haveQuestions')),
        },
        {
            name: NavigationSectionKey.sign_out,
            isActive: section => section === NavigationSectionKey.sign_out,
            shouldBeVisible: () => true,
            onClick: () => SignOutHelper.signOut(),
        },
    ];

    return configs;
}
