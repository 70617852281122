import { DisconnectedError } from 'DisconnectedError';
import { DEFAULT_SIGNUP_LOCATION } from 'SignupLocations';
import { REACT_ROUTER_DIRECTIVE_NAME } from 'NavigationHelpers';
import { getStudentDashboardVersion } from 'StudentDashboard';
import { cleanUpRouteResolution } from 'FrontRoyalAngular';
import { convertCurrentUserFromIguana } from 'Users';
import angularModule from './navigation_module';

//-----------------------------
// Configure $routeProvider
//-----------------------------

angularModule.config([
    '$injector',
    $injector => {
        // We don't actually want to setup any routes in specs as they cause all sorts of confusion
        if (window.RUNNING_IN_TEST_MODE) {
            return;
        }

        const $routeProvider = $injector.get('$routeProvider');
        const AVAILABLE_RESOLVERS = $injector.get('Navigation.RouteResolvers.AVAILABLE_RESOLVERS');

        // --------- SETUP ROUTE RESOLVERS ----------

        let _routeResolvers;

        function getResolvers(_$injector) {
            if (!_routeResolvers) {
                _routeResolvers = _$injector.get('Navigation.RouteResolvers');
            }
            return _routeResolvers;
        }

        const wrappedResolvers = _.fromPairs(
            _.map(AVAILABLE_RESOLVERS, resolverName => {
                const fn = _$injector => getResolvers(_$injector)[resolverName].call();
                fn.resolverName = resolverName;
                return [resolverName, fn];
            }),
        );

        function passthroughTo(directiveName, site, _resolvers, additional) {
            additional = additional || {};

            let routeInfo = {
                template:
                    '<div compile="html" ng-style="{pointerEvents: reactHandlingRoute ? \'none\' : \'auto\'}"></div><div id="react-target" class="h-full" ng-style="{pointerEvents: reactHandlingRoute ? \'auto\' : \'none\'}"></div>',
                controller: 'ParamsPassthroughCtrl',
                directive: directiveName,
                site,
                reloadOnSearch: false,
            };

            if (typeof _resolvers === 'function') {
                _resolvers = [_resolvers];
            }

            const reactHandlesRoute = directiveName === REACT_ROUTER_DIRECTIVE_NAME;

            // Angular route definitions belonging to routes handled by react should not be configured with resolvers.
            // Instead, the corresponding react route should be configured with the appropriate canAccessRoute function.
            // See TutorBotAiAdvisorRoutes.tsx for an example.
            if (reactHandlesRoute && _.some(_resolvers)) {
                throw new Error('Expected to not have resolvers; React should handle resolving route');
            }

            if (!reactHandlesRoute) {
                // We encountered a situation where somebody referenced a non-existent property in `wrappedResolvers`.
                // This is currently the only reason for this check. If we ever have a situation where don't need any
                // sort of async resolving action, we might just want to rely on `$q.when`
                if (!_.some(_resolvers)) {
                    throw new Error('Expected to have resolvers');
                }

                if (!Array.isArray(_resolvers)) {
                    throw new Error('_resolvers should be a function or an array');
                }

                // use _.compact because there could be nulls in here in a case like
                // [
                // THIS_THING_IS_DISABLED ? wrappedResolvers.disable : null
                // ]
                _resolvers = _.compact(_resolvers).map(r =>
                    // console.log(r);
                    // return r;
                    _$injector => {
                        const timeoutId = setTimeout(() => {
                            if (r.resolverName) {
                                console.warn(`resolver '${r.resolverName}' taking more than 5 seconds.`);
                            } else {
                                console.warn('resolver taking more than 5 seconds: ', r.toString());
                            }
                        }, 5000);
                        return r(_$injector).finally(() => {
                            clearTimeout(timeoutId);
                        });
                    },
                );

                const resolvers = {
                    sequence: [
                        '$injector',
                        _$injector => {
                            const sequence = _$injector.get('sequence');
                            const $route = _$injector.get('$route');
                            const offlineModeManager = _$injector.get('offlineModeManager');
                            const $q = _$injector.get('$q');

                            // See comment in front_royal_wrapper_dir about how $routeChange events do not work for this
                            $route.frontRoyalIsResolvingRoute = true;

                            let promise = $q.when(true);

                            // If the route is handled by both angular and react, we need to know whether we should
                            // let angular handle the route resolution or let react handle it. The route should be
                            // configured with a function that returns a promise that resolves to true if angular
                            // should handle the route resolution, and false if react should handle it.
                            if (additional.handledByBothAngularAndReact) {
                                if (!additional.angularShouldHandleRouteResolution) {
                                    throw new Error(
                                        'angularShouldHandleRouteResolution is required when handledByBothAngularAndReact is true',
                                    );
                                }
                                promise = additional.angularShouldHandleRouteResolution(_$injector);
                            }

                            let angularShouldHandleRouteResolution;
                            return promise
                                .then(_angularShouldHandleRouteResolution => {
                                    angularShouldHandleRouteResolution = !!_angularShouldHandleRouteResolution;
                                    $route.current.$$route.routeHandledBy = angularShouldHandleRouteResolution
                                        ? 'angular'
                                        : 'react';

                                    if (!angularShouldHandleRouteResolution) {
                                        $route.current.$$route.directive = REACT_ROUTER_DIRECTIVE_NAME;
                                        return $q.when();
                                    }

                                    return sequence(_resolvers, resolver => resolver(_$injector));
                                })
                                .catch(err => {
                                    // If a DisconnectedError bubbles up to here,
                                    // then we want to switch to offline mode,  See for
                                    // example, the call to rejectInOfflineMode in route_resolvers.js
                                    if (err.constructor === DisconnectedError) {
                                        // resolveRoute returns a native promise, so wrap it
                                        // in $q
                                        return $q.when(offlineModeManager.resolveRoute());
                                    }

                                    throw err;
                                })
                                .catch(err => {
                                    // angular routing is going to eat this error, so put it in the console first
                                    // eslint-disable-next-line no-console
                                    if (!err?.redirect) {
                                        console.error('Routing error: ', err);
                                    }
                                    throw err;
                                })
                                .finally(() => {
                                    if (!angularShouldHandleRouteResolution) {
                                        return;
                                    }

                                    cleanUpRouteResolution(_$injector);
                                });
                        },
                    ],
                };

                routeInfo.resolve = {
                    loadGlobalDependencies: [
                        '$injector',
                        _$injector => {
                            const RouteAssetLoader = _$injector.get('Navigation.RouteAssetLoader');
                            return RouteAssetLoader.loadGlobalDependencies();
                        },
                    ],
                };
                routeInfo.resolve = angular.extend(routeInfo.resolve, resolvers);
            }

            routeInfo = angular.extend(routeInfo, additional);
            return routeInfo;
        }

        function hardRedirectTo(location) {
            return {
                resolve: {
                    redirectToEmployersSignup: [
                        '$injector',
                        _$injector => {
                            if (typeof location === 'function') {
                                location = location(_$injector);
                            }

                            const NavigationHelperMixin = _$injector.get('Navigation.NavigationHelperMixin');
                            const $q = _$injector.get('$q');
                            NavigationHelperMixin.loadUrl(window.location.origin + location, '_self');

                            return $q(() => {});
                        },
                    ],
                },
            };
        }

        const reactLearnerPage = passthroughTo(REACT_ROUTER_DIRECTIVE_NAME, 'public', [], {
            headerLayout: 'learner',
            reloadOnUrl: false,
        });

        //------------------------------------------------------
        // Publicly accessible routes
        //------------------------------------------------------

        $routeProvider

            // Redirect to the main dashboard, usually the student dashboard
            .when('/home', {
                resolve: {
                    redirectToHomeResolver: ['$injector', wrappedResolvers.redirectToHome],
                },
                headerLayout: 'default',
            })

            .when('/', {
                resolve: {
                    redirectToRootDefault: ['$injector', wrappedResolvers.redirectToRootDefault],
                },
            })

            //------------------------------------------------------
            // Onboarding desktop register / login routes
            //------------------------------------------------------

            .when(
                '/sign-in',
                passthroughTo('onboarding-login', 'public', wrappedResolvers.redirectToHomeIfAuthorized, {
                    useShell: false,
                }),
            )

            .when('/register', {
                redirectTo: '/settings/application',
            })

            .when(
                '/forgot-password',
                passthroughTo('onboarding-forgot-password', 'public', wrappedResolvers.redirectToHomeIfAuthorized, {
                    useShell: false,
                }),
            )

            //------------------------------------------------------
            // Institutional register / login routes
            //------------------------------------------------------

            // Sign-In
            .when(
                '/:url_prefix/sign-in',
                passthroughTo('sign-in', 'public', wrappedResolvers.redirectToHomeIfAuthorized, {
                    useShell: false,
                }),
            )

            // Join Form
            .when('/:url_prefix/join', {
                resolve: {
                    redirectToHomeIfAuthorizedResolver: ['$injector', wrappedResolvers.redirectToHomeIfAuthorized],
                    loadJoinAndRedirectResolver: ['$injector', wrappedResolvers.loadJoinAndRedirect],
                },
            })

            .when(
                '/candidates/signup',
                hardRedirectTo(() => DEFAULT_SIGNUP_LOCATION),
            )

            // Institution registration
            .when(
                '/:url_prefix/join/account',
                passthroughTo('institution-register', 'public', [wrappedResolvers.redirectToHomeIfAuthorized], {
                    useShell: false,
                }),
            )

            // alias for '/:url_prefix/join/account'
            .when(
                '/:url_prefix/candidates/signup',
                passthroughTo('institution-register', 'public', [wrappedResolvers.redirectToHomeIfAuthorized], {
                    useShell: false,
                }),
            )

            // Forgot Password
            .when(
                '/:url_prefix/forgot-password',
                passthroughTo('forgot-password', 'public', wrappedResolvers.redirectToHomeIfAuthorized, {
                    useShell: false,
                }),
            )

            // Choose your institution
            .when('/choose-institution', reactLearnerPage)

            //------------------------------------------------------
            // Onboarding Cordova register / login routes
            //------------------------------------------------------

            .when(
                '/onboarding/hybrid',
                passthroughTo(
                    'onboarding-hybrid-start',
                    'public',
                    [
                        wrappedResolvers.redirectToHomeIfAuthorized,
                        wrappedResolvers.redirectToOnboardingRegisterIfMiyaMiya,
                    ],
                    {
                        useShell: false,
                    },
                ),
            )

            .when(
                '/onboarding/hybrid/questionary',
                passthroughTo(
                    'onboarding-hybrid-questionary',
                    'public',
                    [
                        wrappedResolvers.redirectToHomeIfAuthorized,
                        wrappedResolvers.redirectToOnboardingRegisterIfMiyaMiya,
                    ],
                    {
                        useShell: false,
                    },
                ),
            )

            .when(
                '/onboarding/hybrid/register',
                passthroughTo('onboarding-hybrid-register', 'public', wrappedResolvers.redirectToHomeIfAuthorized, {
                    useShell: false,
                }),
            )

            .when(
                '/onboarding/hybrid/login',
                passthroughTo('onboarding-hybrid-login', 'public', wrappedResolvers.redirectToHomeIfAuthorized, {
                    useShell: false,
                }),
            )

            .when(
                '/onboarding/hybrid/forgot-password',
                passthroughTo(
                    'onboarding-hybrid-forgot-password',
                    'public',
                    wrappedResolvers.redirectToHomeIfAuthorized,
                    {
                        useShell: false,
                    },
                ),
            )

            // logged-out. SAML users see this after logout.  If we sent them to the sign-in page,
            // they would be automatically signed in.
            .when(
                '/logged-out',
                passthroughTo('logged-out-page', 'public', wrappedResolvers.redirectToHomeIfAuthorized, {
                    useShell: false,
                }),
            )

            //------------------------------------------------------
            // Learner / content routes
            //------------------------------------------------------

            // redirect old routes
            .when('/playlist/library', {
                redirectTo: '/dashboard',
            })

            // redirect old routes
            .when('/playlist/:playlist_title/:playlist_id', {
                redirectTo: () => 'dashboard',
            })

            .when(
                '/deferral/:id',
                passthroughTo('deferral-link', 'public', [wrappedResolvers.hasLearnerAccess], {
                    headerLayout: 'default',
                }),
            )

            .when('/settings', {
                redirectTo: '/settings/account',
            })

            // Rolled notifications content into preferences https://github.com/quanticedu/back_royal/pull/10888

            .when('/settings/notifications', {
                redirectTo: '/settings/preferences',
            })

            // In commit 1d284b3 we moved the network settings tab to a step in the my-profile section,
            // so continue to support the now legacy url by taking the user to that new my-profile section.
            // Note: We wanted to send them to the exact page, but after running into a number of issues we
            // decided to stop spending time since this is edge-casey and likely to be removed in the future.
            .when('/settings/network', {
                redirectTo: '/settings/my-profile',
            })

            // Add an email address if it's missing
            .when(
                '/complete-registration',
                passthroughTo('complete-registration', 'public', wrappedResolvers.profileConfirmationRequired, {
                    useShell: false,
                }),
            )

            // Main settings view (with support for optional section passthrough)
            .when(
                '/settings/:section?',
                passthroughTo('settings', 'public', [wrappedResolvers.hasLearnerAccess], {
                    headerLayout: 'default',

                    // Since the settings page is where has_seen_welcome gets set to true, we need to be able
                    // to get there even before onboarding is complete.
                    allowedWhenOnboardingIncomplete: true,
                }),
            )

            // more specific specification to pass page values directly to settings scope for underlying nav
            .when(
                '/settings/:section/?page=:page',
                passthroughTo('settings', 'public', [wrappedResolvers.hasLearnerAccess], {
                    headerLayout: 'default',

                    // Since the settings page is where has_seen_welcome gets set to true, we need to be able
                    // to get there even before onboarding is complete.
                    allowedWhenOnboardingIncomplete: true,
                }),
            )

            // Student Dashboard. passthroughTo will process this route and determine if it angular or react
            // should handle the route resolution. If it's determined that react should handle the route resolution,
            // then passthroughTo will change the directive to REACT_ROUTER_DIRECTIVE_NAME on the fly. This way the
            // student-dashboard-v1 directive doesn't need to conditionally hide its contents and can instead remain
            // ignorant of whether react or angular is handling the route.
            .when(
                '/dashboard',
                passthroughTo('student-dashboard', 'public', wrappedResolvers.hasLearnerAccess, {
                    headerLayout: 'learner',
                    handledByBothAngularAndReact: true,
                    angularShouldHandleRouteResolution: _$injector =>
                        getResolvers(_$injector)
                            .waitForAuthCallCompleteOrRedirectToSignIn()
                            .then(currentUser => {
                                currentUser = convertCurrentUserFromIguana(currentUser);
                                const config = _$injector.get('ConfigFactory').getSync();
                                return getStudentDashboardVersion(currentUser, config) === 1;
                            }),
                }),
            )

            // just for local debugging, force into v1 dashboard
            .when(
                '/dashboard-v1',
                passthroughTo('student-dashboard', 'public', wrappedResolvers.hasLearnerAccess, {
                    headerLayout: 'learner',
                }),
            )
            .when('/dashboard/:section', reactLearnerPage)

            // Browse Courses (full list with searching)
            .when(
                '/courses',
                passthroughTo('browse-courses', 'public', wrappedResolvers.unrestrictedAccess, {
                    headerLayout: 'learner',
                }),
            )

            // Resources
            .when('/resources/:category?', reactLearnerPage)

            // Nominations
            .when(
                '/nominations/:section?',
                passthroughTo(REACT_ROUTER_DIRECTIVE_NAME, 'public', [], { headerLayout: 'learner' }),
            )

            // Browse Courses was moved from library to courses
            .when('/library', {
                redirectTo: '/courses',
            })

            // Careers Network tab has been deprecated
            .when('/careers', {
                redirectTo: '/dashboard',
            })

            .when('/careers/:section', {
                redirectTo: '/dashboard',
            })

            // edit career profile was moved from careers context to settings
            .when('/careers/edit', {
                redirectTo: '/settings/my-profile',
            })

            // Student Network (map + alumni directory) with optional event-id param support
            .when(
                '/student-network',
                passthroughTo('student-network', 'public', wrappedResolvers.hasStudentNetworkEventsAccessForEventId, {
                    headerLayout: 'learner',
                }),
            )

            // Playing a lesson
            .when(
                '/course/:stream_id/chapter/:chapter_index/lesson/:lesson_id/show',
                passthroughTo('show-stream', 'public', wrappedResolvers.unrestrictedAccess, {
                    headerLayout: 'lesson-player',
                }),
            )

            // Playing a lesson with scorm enabled
            .when(
                '/course/:mode/:stream_id/chapter/:chapter_index/lesson/:lesson_id/show',
                passthroughTo('show-stream', 'public', wrappedResolvers.scormAccess, {
                    headerLayout: 'lesson-player',
                }),
            )

            // Playing a lesson with the old url with 'stream' instead of 'course', just
            // so we don't break links in trello tickets
            .when(
                '/stream/:stream_id/chapter/:chapter_index/lesson/:lesson_id/show',
                passthroughTo('show-stream', 'public', wrappedResolvers.unrestrictedAccess, {
                    headerLayout: 'lesson-player',
                }),
            )

            // Practice player
            .when(
                '/practice/show/:course_or_lesson/:content_item_id',
                passthroughTo('show-practice-player', 'public', wrappedResolvers.hasSuperEditorAccess, {
                    headerLayout: 'lesson-player',
                }),
            )

            // Course complete page
            .when(
                '/course/:stream_id/completed',
                passthroughTo('stream-completed', 'public', wrappedResolvers.hasLearnerAccess, {
                    headerLayout: 'learner',
                }),
            )

            // Daily lesson page / seo friendly URL lesson page
            .when(
                '/lesson/:lesson_title/show/:lesson_id',
                passthroughTo('show-standalone-lesson', 'public', wrappedResolvers.unrestrictedAccess, {
                    headerLayout: 'lesson-player',
                }),
            )

            // Seo friendly URL lesson page
            .when(
                '/lesson/:lesson_title/:lesson_id',
                passthroughTo('show-standalone-lesson', 'public', wrappedResolvers.unrestrictedAccess, {
                    headerLayout: 'lesson-player',
                }),
            )

            // Demo lesson page
            .when(
                '/lesson/:mode/:lesson_title/:lesson_id',
                passthroughTo('show-standalone-lesson', 'public', wrappedResolvers.demoAccess, {
                    headerLayout: 'lesson-player',
                }),
            )

            // Course Dashboard
            .when(
                '/course/:course_title/:stream_id',
                passthroughTo('stream-dashboard', 'public', wrappedResolvers.unrestrictedAccess, {
                    headerLayout: 'learner',
                }),
            )

            // Alternate routes supporting no title (for non-Latin based languages, and also For LaunchLessonTest)
            .when(
                '/course/:stream_id',
                passthroughTo('stream-dashboard', 'public', wrappedResolvers.unrestrictedAccess, {
                    headerLayout: 'learner',
                }),
            )

            .when(
                '/lesson/:lesson_id',
                passthroughTo('show-standalone-lesson', 'public', wrappedResolvers.unrestrictedAccess, {
                    headerLayout: 'lesson-player',
                }),
            )

            //------------------------------------------------------
            // Editor routes
            //------------------------------------------------------

            .when(
                '/editor',
                passthroughTo('editor-index', 'editor', wrappedResolvers.hasEditorAccess, {
                    headerLayout: 'default',
                }),
            )

            // there is no bot directive, but nothing needs to be rendered, so that is fine
            .when(
                '/editor/bot',
                passthroughTo('bot', 'editor', wrappedResolvers.hasEditorAccess, {
                    headerLayout: 'default',
                }),
            )

            // need a public bot when we need public styling, but only editors can access it
            .when(
                '/public/bot',
                passthroughTo('bot', 'public', wrappedResolvers.hasEditorAccess, {
                    headerLayout: 'default',
                }),
            )

            .when(
                '/editor/lesson/:lesson_id/edit',
                passthroughTo('edit-lesson', 'editor', wrappedResolvers.hasEditorAccess, {
                    headerLayout: 'lesson-editor',
                }),
            )

            .when(
                '/editor/lesson/:lesson_id/diff/:version_id_1/:version_id_2',
                passthroughTo('lesson-diff', 'editor', wrappedResolvers.hasEditorAccess, {
                    headerLayout: 'default',
                }),
            )

            .when(
                '/editor/course/:stream_id/edit',
                passthroughTo('edit-stream', 'editor', wrappedResolvers.hasSuperEditorAccess, {
                    headerLayout: 'default',
                }),
            )

            .when(
                '/editor/playlist/:playlist_id/edit',
                passthroughTo('edit-playlist', 'editor', wrappedResolvers.hasSuperEditorAccess, {
                    headerLayout: 'default',
                }),
            )

            .when(
                '/editor/lesson/preview',
                passthroughTo('preview-lesson', 'public', wrappedResolvers.hasEditorAccess, {
                    headerLayout: 'lesson-player',
                }),
            )

            //------------------------------------------------------
            // Admin routes
            //------------------------------------------------------

            // Redirect the legacy users list (/users/all) to the applicants list which is
            // the default (and now only) view of /admin/users. Also, clean up the url to remove the
            // /applicants part. We could let the :section? route handle this, but it
            // would leave the /applicants or /all part in the url, which is not ideal.
            .when('/admin/users/applicants', {
                redirectTo: '/admin/users',
            })
            .when('/admin/users/all', {
                redirectTo: '/admin/users',
            })
            .when(
                '/admin/users',
                passthroughTo('admin-users', 'admin', wrappedResolvers.hasSuperEditorOrInterviewerAccess, {
                    headerLayout: 'default',
                }),
            )

            .when('/admin/batch-users', {
                redirectTo: '/admin/batch-users/select-users',
            })

            .when(
                '/admin/batch-users/:section?',
                passthroughTo('batch-edit-users', 'admin', wrappedResolvers.hasAdminAccess, {
                    headerLayout: 'default',
                }),
            )

            .when('/admin/mba', {
                redirectTo: '/admin/mba/calendar',
            })

            .when(
                '/admin/mba/:section?',
                passthroughTo('admin-mba', 'admin', wrappedResolvers.hasAdminAccess, {
                    headerLayout: 'default',
                }),
            )

            .when(
                '/admin/groups',
                passthroughTo('admin-groups', 'admin', wrappedResolvers.hasAdminAccess, {
                    headerLayout: 'default',
                }),
            )

            .when(
                '/admin/global-metadata',
                passthroughTo('admin-global-metadata', 'admin', wrappedResolvers.hasAdminAccess, {
                    headerLayout: 'default',
                }),
            )

            .when(
                '/reports',
                passthroughTo('reports', 'reports', wrappedResolvers.hasReportsAccess, {
                    headerLayout: 'default',
                }),
            )

            .when('/bot', passthroughTo(REACT_ROUTER_DIRECTIVE_NAME, 'public', [], { headerLayout: 'learner' }))

            //------------------------------------------------------
            // Cordova no-network route
            //------------------------------------------------------

            .when(
                '/disconnected-mobile-init',
                passthroughTo('disconnected-mobile-init', 'public', wrappedResolvers.hasCordovaNoNetwork, {
                    useShell: false,
                }),
            )

            //------------------------------------------------------
            // Fallthrough / 404 route
            //------------------------------------------------------

            .otherwise({
                resolve: {
                    handle404: ['$injector', wrappedResolvers.handle404],
                },
            });
    },
]);
