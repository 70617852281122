/* eslint-disable max-lines-per-function */
import { Popover as HeroUIPopover, PopoverTrigger, PopoverContent, Button } from '@heroui/react';
import React, { useCallback, useEffect, useMemo, useRef, useState, type FC } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { type PopoverProps } from './Popover.types';
import { arrowClasses } from './arrowClasses';

export const Popover: FC<PopoverProps> = ({
    placement,
    children,
    Content,
    onClose,
    borderColor,
    classNames,
    isOpenedInitially = false,
    allowOpen = true,
    openDelayMs = 0,
    closeOnOverlayClick = true,
    arrow = true,
    updatePositionDeps,
    onCloseButtonClick,
    closeButton = false,
}) => {
    const [isOpen, setIsOpen] = useState(() => isOpenedInitially && !openDelayMs);
    const triggerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const close = useCallback(() => {
        setIsOpen(false);
        onClose?.();
    }, [onClose]);

    useEffect(() => {
        if (!allowOpen) return undefined;
        let timeout: NodeJS.Timeout;

        if (isOpenedInitially && openDelayMs) {
            timeout = setTimeout(() => {
                setIsOpen(true);
            }, openDelayMs);
        }

        return () => clearTimeout(timeout);
    }, [isOpenedInitially, openDelayMs, allowOpen]);

    const baseClass = useMemo(() => {
        const className = twMerge(
            arrowClasses,
            'data-[focus-visible=true]:outline-hidden data-[focus=true]:outline-hidden',
            classNames?.arrow,
            classNames?.base,
        );
        if (!borderColor) return `${className} before:border-none`;

        const classWithBorder = `${className} before:border before:border-solid`;

        if (borderColor === 'black') return `${classWithBorder} before:border-black`;

        if (borderColor === 'slate-grey-200') return `${classWithBorder} before:border-slate-grey-200`;

        if (borderColor === 'transparent') return `${classWithBorder} before:border-transparent`;

        return className;
    }, [borderColor, classNames?.arrow, classNames?.base]);

    const contentClass = useMemo(() => {
        const className = twMerge(classNames?.content);
        if (!borderColor) return className;

        const borderClass = `${className} border border-solid shadow-[0px_5px_10px_0px_rgba(1,1,1,.2)]`;

        if (borderColor === 'black') return `${borderClass} border-black`;

        if (borderColor === 'slate-grey-200') return `${borderClass} border-slate-grey-200`;

        if (borderColor === 'transparent') return `${borderClass} border-transparent`;

        return '';
    }, [borderColor, classNames?.content]);

    return (
        <HeroUIPopover
            placement={placement}
            ref={contentRef}
            isOpen={allowOpen ? isOpen : false}
            motionProps={{ layout: true }}
            updatePositionDeps={updatePositionDeps}
            triggerScaleOnOpen={false}
            // onClose is only called when shouldCloseOnInteractOutside returns true
            // which is a bit confusing, it is not always called when the controlled
            // state "isOpen" is changed to false
            // which is why the "close" function is created above
            onClose={close}
            shouldCloseOnInteractOutside={el => {
                if (closeOnOverlayClick) return true;

                // if closeOnOverlayClick is false, we still want
                // the children/trigger passed into the popover to
                // close it when clicked
                if (triggerRef.current?.contains(el)) return true;

                return false;
            }}
            onOpenChange={open => {
                if (open) {
                    setIsOpen(true);
                    return;
                }
                if (!closeOnOverlayClick) return;
                setIsOpen(false);
            }}
            showArrow={arrow}
            triggerRef={triggerRef}
            offset={15}
            className="tailwind-base-container"
            classNames={{
                base: baseClass,
                content: contentClass,
                trigger: classNames?.trigger,
                backdrop: classNames?.backdrop,
            }}
        >
            <PopoverTrigger>{children}</PopoverTrigger>

            <PopoverContent>
                {titleProps => (
                    <div className="relative">
                        {closeButton && (
                            <Button
                                isIconOnly
                                size="lg"
                                className={clsx(
                                    'data-[focus-visible=true]:outline-hidden absolute -right-4 z-10',
                                    '-top-4 h-10 w-10 min-w-10 rounded-full border border-solid',
                                    'border-slate-grey-200 bg-white shadow-none',
                                )}
                                onPress={() => {
                                    onCloseButtonClick?.();
                                    close();
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes} className="text-lg text-black" />
                            </Button>
                        )}
                        <Content titleProps={titleProps} close={close} />
                    </div>
                )}
            </PopoverContent>
        </HeroUIPopover>
    );
};
