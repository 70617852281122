window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.navigation = window.locales.am.navigation || {};window.locales.am.navigation.app_menu = window.locales.am.navigation.app_menu || {};window.locales.am.navigation.app_menu = {...window.locales.am.navigation.app_menu, ...{
    "admin_tools": "አስተዳዳሪ",           
    "choose_a_group": "ቡድን ይምረጡ",           
    "clear_progress": "እድገትን ያጽዱ",           
    "clear_progress_confirm": "ሁሉንም የትምህርት እድገትዎን ለማጽዳት እንደሚፈልጉ እርግጠኛ ነዎት?",           
    "clear_progress_success": "የነበረ የትምህርት እድገት በተሳካ ሁኔታ ጸድቷል",           
    "dashboard": "ዳሽቦርድ",           
    "edit_content": "ይዘት አርትእ",           
    "frame_events_report": "የፍሬም ክስተት ሪፖርት",           
    "give_feedback": "ግብረ መልስ ይስጡ",           
    "grade_lessons": "የደረጃ ትምህርቶች",           
    "home": "መነሻ",           
    "courses": "ኮርሶች",           
    "careers": "ስራዎች",           
    "student_network": "አውታረ መረብ",           
    "playlists": "የአጫዋች ዝርዝሮች",           
    "login": "ይግቡ",           
    "manage_global_metadata": "ሜታዳታ ያስተዳድሩ",           
    "manage_cohorts": "Cohorts ያስተዳድሩ",           
    "manage_groups": "ቡድኖችን ያስተዳድሩ",           
    "manage_institutions": "ተቋማትን ያስተዳድሩ",           
    "manage_users": "ተጠቃሚዎችን ያስተዳድሩ",           
    "batch_edit_users": "ብዙ ተጠቃሚዎችን አርትእ",           
    "reports": "ሪፖርቶች",           
    "pricing": "ዋጋ",           
    "settings": "ቅንብሮች",           
    "sign_up": "ይመዝገቡ",           
    "source": "ምንጭ",           
    "inbox": "የገቢ መልዕክት ሳጥን",           
    "manage_careers": "ስራዎችን ያስተዳድሩ",           
    "mba": "MBA እና ተቋማትን ያስተዳድሩ",           
    "post": "ለጥፍ",           
    "resources": "መርጃዎች",
    "ai": "AI",
    "ai_bot": "AI Bot",
    "beta": "ቤታ",
    "nominations": "እጩዎች",
    "application": "መተግበሪያ",
    "apply": "ያመልክቱ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.navigation = window.locales.ar.navigation || {};window.locales.ar.navigation.app_menu = window.locales.ar.navigation.app_menu || {};window.locales.ar.navigation.app_menu = {...window.locales.ar.navigation.app_menu, ...{
    "admin_tools": "المشرف",           
    "choose_a_group": "اختر مجموعة",           
    "clear_progress": "مسح التقدم",           
    "clear_progress_confirm": "هل أنت متأكد من أنك تريد مسح كل التقدم الذي أحرزته في الدرس؟",           
    "clear_progress_success": "تم مسح التقدم الذي أحرزته في الدرس الحالي بنجاح",           
    "dashboard": "لوحة التحكم",           
    "frame_events_report": "تقرير أحداث الإطار",           
    "give_feedback": "امنحنا آرائك واقتراحاتك",           
    "grade_lessons": "دروس الصف",           
    "home": "الصفحة الرئيسية",           
    "careers": "الوظائف",           
    "playlists": "قوائم التشغيل",           
    "login": "تسجيل الدخول",           
    "manage_global_metadata": "إدارة البيانات الوصفية",           
    "manage_cohorts": "إدارة المجموعات",           
    "manage_groups": "إدارة المجموعات",           
    "manage_institutions": "إدارة المؤسسات",           
    "manage_users": "إدارة المستخدمين",           
    "reports": "التقارير",           
    "pricing": "السعر",           
    "settings": "الإعدادات",           
    "sign_up": "الاشتراك",           
    "activity": "النشاط",           
    "connections": "الاتصالات",           
    "batch_edit_users": "تعديل متعددة المستخدمين",
    "manage_careers": "إدارة وظائف",
    "edit_content": "تحرير المحتوى",
    "mba": "إدارة MBA والمؤسسات",
    "student_network": "شبكة الاتصال",
    "source": "مصدر",
    "inbox": "صندوق الوارد",
    "post": "بريد",
    "courses": "الدورات",
    "resources": "موارد",
    "ai": "منظمة العفو الدولية",
    "ai_bot": "الذكاء الاصطناعي بوت",
    "beta": "بيتا",
    "nominations": "الترشيحات",
    "application": "طلب",
    "apply": "يتقدم"
}
};window.locales.en = window.locales.en || {};window.locales.en.navigation = window.locales.en.navigation || {};window.locales.en.navigation.app_menu = window.locales.en.navigation.app_menu || {};window.locales.en.navigation.app_menu = {...window.locales.en.navigation.app_menu, ...{
    "admin_tools": "Admin",
    "choose_a_group": "Choose a group",
    "clear_progress": "Clear Progress",
    "clear_progress_confirm": "Are you sure you want to clear all your lesson progress?",
    "clear_progress_success": "Successfully cleared existing lesson progress",
    "dashboard": "Dashboard",
    "edit_content": "Edit Content",
    "frame_events_report": "Frame Events Report",
    "give_feedback": "Give Feedback",
    "grade_lessons": "Grade Lessons",
    "home": "Home",
    "courses": "Courses",
    "careers": "Careers",
    "student_network": "Network",
    "playlists": "Playlists",
    "login": "Login",
    "manage_global_metadata": "Manage Metadata",
    "manage_cohorts": "Manage Cohorts",
    "manage_groups": "Manage Groups",
    "manage_institutions": "Manage Institutions",
    "manage_users": "Manage Users",
    "batch_edit_users": "Edit Multiple Users",
    "reports": "Reports",
    "pricing": "Pricing",
    "settings": "Settings",
    "sign_up": "Sign Up",
    "source": "Source",
    "inbox": "Inbox",
    "manage_careers": "Manage Careers",
    "mba": "Manage MBA and Institutions",
    "post": "Post",
    "resources": "Resources",
    "ai": "AI",
    "ai_bot": "AI Bot",
    "beta": "beta",
    "nominations": "Nominations",
    "application": "Application",
    "apply": "Apply"
}
};window.locales.es = window.locales.es || {};window.locales.es.navigation = window.locales.es.navigation || {};window.locales.es.navigation.app_menu = window.locales.es.navigation.app_menu || {};window.locales.es.navigation.app_menu = {...window.locales.es.navigation.app_menu, ...{
    "admin_tools": "Administrador",           
    "choose_a_group": "Elegir un grupo",           
    "clear_progress": "Eliminar progreso",           
    "clear_progress_confirm": "¿Seguro que deseas borrar todo tu progreso en la lección?",           
    "clear_progress_success": "Progreso existente en la lección borrado con éxito",           
    "dashboard": "Panel de control",           
    "frame_events_report": "Informe de eventos enmarcados",           
    "give_feedback": "Dejar comentarios",           
    "grade_lessons": "Lecciones de grado",           
    "home": "Inicio ",           
    "careers": "Carreras",           
    "login": "Iniciar sesión",           
    "manage_global_metadata": "Gestionar metadatos",           
    "manage_groups": "Gestionar grupos",           
    "manage_institutions": "Gestionar instituciones",           
    "manage_users": "Gestionar usuarios",           
    "reports": "Informes",           
    "pricing": "Precios",           
    "settings": "Ajustes",           
    "sign_up": "Regístrate",           
    "playlists": "Playlists",           
    "manage_cohorts": "Gestionar cursos en grupo",           
    "batch_edit_users": "Editar varios usuarios",
    "manage_careers": "Manejo de Carreras",
    "edit_content": "Editar contenido",
    "mba": "Manejo de MBA y las instituciones",
    "student_network": "Red",
    "source": "Fuente",
    "inbox": "Bandeja de entrada",
    "post": "Enviar",
    "courses": "Cursos",
    "resources": "Recursos",
    "ai": "AI",
    "ai_bot": "Robot de IA",
    "beta": "beta",
    "nominations": "Nominaciones",
    "application": "Solicitud",
    "apply": "Aplicar"
}
};window.locales.it = window.locales.it || {};window.locales.it.navigation = window.locales.it.navigation || {};window.locales.it.navigation.app_menu = window.locales.it.navigation.app_menu || {};window.locales.it.navigation.app_menu = {...window.locales.it.navigation.app_menu, ...{
    "admin_tools": "Amministratore",           
    "choose_a_group": "Scegli un gruppo",           
    "clear_progress": "Cancella Progressi",           
    "clear_progress_confirm": "Sei sicuro di voler cancellare tutti i tuoi progressi nella lezione?",           
    "clear_progress_success": "Progressi lezione esistenti cancellati con successo",           
    "dashboard": "Bacheca",           
    "edit_content": "Modifica Contenuto",           
    "frame_events_report": "Rapporto Frame Event",           
    "give_feedback": "Invia Feedback",           
    "grade_lessons": "Valuta Lezioni",           
    "home": "Home",           
    "careers": "Carriere",           
    "playlists": "Playlist",           
    "login": "Accesso",           
    "manage_global_metadata": "Gestisci Metadati",           
    "manage_cohorts": "Gestisci Sessioni Accademiche",           
    "manage_groups": "Gestisci Gruppi",           
    "manage_institutions": "Gestisci Istituzioni",           
    "manage_users": "Gestisci Utenti",           
    "batch_edit_users": "Modifica più Utenti",           
    "reports": "Riepiloghi",           
    "pricing": "Tariffe",           
    "settings": "Impostazioni",           
    "sign_up": "Registrati",           
    "manage_careers": "Gestisci Carriere",           
    "mba": "Gestisci MBA e Istituzioni",           
    "student_network": "Rete",
    "source": "fonte",
    "inbox": "Posta in arrivo",
    "post": "Inviare",
    "courses": "Corsi",
    "resources": "Risorse",
    "ai": "AI",
    "ai_bot": "Bot AI",
    "beta": "beta",
    "nominations": "Nomine",
    "application": "Applicazione",
    "apply": "Fare domanda a"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.navigation = window.locales.zh.navigation || {};window.locales.zh.navigation.app_menu = window.locales.zh.navigation.app_menu || {};window.locales.zh.navigation.app_menu = {...window.locales.zh.navigation.app_menu, ...{
    "admin_tools": "管理员",           
    "choose_a_group": "选择一个组",           
    "clear_progress": "清除进度",           
    "clear_progress_confirm": "您是否确定要清除您的所有课业进度？",           
    "clear_progress_success": "已成功清除现有的课业进度",           
    "dashboard": "控制面板",           
    "frame_events_report": "逐项事件报告",           
    "give_feedback": "提供反馈",           
    "grade_lessons": "年级课业",           
    "home": "主页",           
    "careers": "职业",           
    "playlists": "任务列表",           
    "login": "登录",           
    "manage_global_metadata": "管理元数据",           
    "manage_cohorts": "管理同批人",           
    "manage_groups": "管理组",           
    "manage_institutions": "管理机构",           
    "manage_users": "管理用户",           
    "reports": "报告",           
    "pricing": "价格",           
    "raw_events_report": "原始事件报告",           
    "settings": "设置",           
    "sign_up": "注册",           
    "time_on_task_report": "任务时间报告",           
    "batch_edit_users": "编辑多用户",           
    "manage_careers": "职业管理",           
    "edit_content": "编辑内容",           
    "mba": "工商管理硕士（MBA）和机构管理",           
    "student_network": "网络",           
    "source": "寻找",           
    "inbox": "收件箱",           
    "post": "发布",           
    "courses": "课程",           
    "resources": "资源",
    "ai": "人工智能",
    "ai_bot": "人工智能机器人",
    "beta": "贝塔",
    "nominations": "提名",
    "application": "应用",
    "apply": "申请"
}
};