import { Route } from 'react-router-dom';
import { route } from 'react-router-typesafe-routes/dom';
import { ProtectedRoute } from 'ProtectedRoute';

export const ROUTES = {
    NOMINATIONS: route('nominations'),
};

async function getLazyNominationsComponent(component: 'Layout' | 'ScholarshipNominations') {
    const components = await import(/* webpackPrefetch: true */ './Nominations');
    const NominationsComponent = components[component];
    if (component === 'Layout') {
        return {
            Component: () => (
                <ProtectedRoute>
                    <NominationsComponent />
                </ProtectedRoute>
            ),
        };
    }
    return { Component: NominationsComponent };
}

export function getNominationsRoutes() {
    return (
        <Route path={ROUTES.NOMINATIONS.relativePath} lazy={() => getLazyNominationsComponent('Layout')}>
            <Route index lazy={() => getLazyNominationsComponent('ScholarshipNominations')} />
        </Route>
    );
}
