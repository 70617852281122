import 'ng-toast';
import 'angular-selectize2/dist/angular-selectize';
import cacheAngularTemplate from 'cacheAngularTemplate';

import 'Authentication/angularModule';
import 'Careers/angularModule';
import 'DialogModal/angularModule';
import 'ClientStorage/angularModule';
import 'ErrorLogging/angularModule';
import 'EventLogger/angularModule';
import 'FrontRoyalTimer/angularModule';
import 'FrontRoyalUiBootstrap/angularModule';
import 'Institutions/angularModule';
import 'guid/angularModule';
import 'IsMobile/angularModule';
import 'LearnerContentCache/angularModule';
import 'OfflineMode/angularModule';
import 'OnImageLoad/angularModule';
import 'RouteAnimationHelper/angularModule';
import 'SafeApply/angularModule';
import 'ScrollHelper/angularModule';
import 'SearchEngineOptimization/angularModule';
import 'Sequence/angularModule';
import 'Translation/angularModule';
import 'Users/angularModule';

import sessionsNavigationFullWidth from 'Navigation/angularModule/views/sessions_navigation_full_width.html';

const frontRoyalNavigationModule = angular.module('FrontRoyal.Navigation', [
    'FrontRoyal.Careers',
    'LearnerContentCache',
    'scrollHelper',
    'AClassAbove',
    'ClientStorage',
    'EventLogger',
    'FrontRoyal.Institutions',
    'Translation',
    'FrontRoyal.UiBootstrap',
    'FrontRoyal.Users',
    'guid',
    'FrontRoyal.Authentication',
    'FrontRoyalTimer',
    'ngToast',
    'selectize',
    'FrontRoyal.RouteAnimationHelper',
    'onImageLoad',
    'SiteMetadata',
    'DialogModal',
    'FrontRoyal.ErrorLogService',
    'isMobile',
    'safeApply',
    'Sequence',
    'OfflineMode',
    'ngRoute',
]);

angular.module('FrontRoyal.Navigation').run([
    '$injector',
    $injector => {
        const $rootScope = $injector.get('$rootScope');
        const $location = $injector.get('$location');
        const $window = $injector.get('$window');
        const RouteEventHandler = $injector.get('RouteEventHandler');
        const ClientStorage = $injector.get('ClientStorage');
        const RouteAnimationHelper = $injector.get('RouteAnimationHelper');
        const SiteMetadata = $injector.get('SiteMetadata');
        const scrollHelper = $injector.get('scrollHelper');
        const $route = $injector.get('$route');

        Object.defineProperty($rootScope, 'homePath', {
            get() {
                const user = $rootScope.currentUser;

                if (!user) return $window.CORDOVA ? '/onboarding/hybrid' : '/';

                return '/dashboard';
            },
            configurable: true,
        });

        Object.defineProperty($rootScope, 'postLogoutSignInPath', {
            get: () => {
                // At one point this was an if / else if because we were building
                // an app for the external institution Uber, which we did NOT want to
                // touch the onboarding experience. But then we built an app for
                // Miya Miya, which we DID want to use the onboarding experience. So
                // if we ever need to do something similar to Uber again then we'll need
                // to reconcile this forcedUrlPrefix and onboarding logic appropriately.
                // if ($window.CORDOVA.forcedUrlPrefix) {
                //     $location.path(`/${$window.CORDOVA.forcedUrlPrefix}/sign-in`);
                if ($window.CORDOVA) {
                    return 'onboarding/hybrid/login';
                }
                return '/sign-in';
            },
        });

        $rootScope.goHome = () => {
            $location.path($rootScope.homePath);
        };

        // see comment in route_resolvers.js
        $rootScope.redirectHomeWhileRouting = () => {
            // If we're sending the user to the place they were going anyway,
            // we have to resolve the original promise.  Redirect won't work
            if ($route.current.$$route.originalPath === $rootScope.homePath) {
                return;
            }

            // If we're sending them to a new place, then we reject with a redirect
            // eslint-disable-next-line no-throw-literal
            throw { redirect: $rootScope.homePath };
        };

        $rootScope.pushBackButtonHistory = (currentUrl, currentDirective) => {
            // we like to keep track of this in Cordova in case of transparent app restarts, etc. (see also: ValidationResponder::forwardToTargetUrl)
            // We only store this if there is a logged in user.
            if ($window.CORDOVA && $rootScope.currentUser) {
                ClientStorage.setItem('last_visited_route', currentUrl);
            }

            $rootScope.pageHistory.push({
                url: currentUrl,
                directive: currentDirective,
            });
        };

        $rootScope.back = animate => {
            animate = angular.isDefined(animate) ? animate : true;

            // if we don't have any in-app history, but we have a recorded history entry and it
            // matches the same domain as the application, then just back-nav. otherwise allow defaults.
            if (
                !window.CORDOVA &&
                $rootScope.pageHistory.length <= 1 &&
                document.referrer &&
                document.referrer.match(/:\/\/(.[^/]+)/)[1] === window.location.host
            ) {
                const path = document.referrer.substring(document.referrer.lastIndexOf('/'));
                // if we're not logged in, or the referrer path isn't to /, use referrer
                // this is because if we're logged in and the path is to /, we want to be
                // smarter about which root to send them to
                if (!$rootScope.currentUser || path !== '/') {
                    window.location.assign(document.referrer);
                    return;
                }
            }

            // if there was no referrer, or the referrer didn't match our domain, or there was no history, etc.
            // we might need to send them "back" to a root URL. Choose which one based on whether they are logged in or not.
            const defaultRootURL = $rootScope.homePath;

            // pop the previous URL off the stack or default to proper root URL
            const prevUrl =
                $rootScope.pageHistory.length > 1 ? $rootScope.pageHistory.splice(-2)[0].url : defaultRootURL;

            if (animate) {
                RouteAnimationHelper.animatePathChange(prevUrl, 'slide-right');
            } else {
                $location.url(prevUrl);
                scrollHelper.scrollToTop();
            }

            SiteMetadata.updateHeaderMetadata({
                path: prevUrl,
            });
        };

        // Setup history, route change success/error, and backbutton listeners
        RouteEventHandler.setupEventListeners();
    },
]);

cacheAngularTemplate(
    frontRoyalNavigationModule,
    'Navigation/sessions_navigation_full_width.html',
    sessionsNavigationFullWidth,
);

export default frontRoyalNavigationModule;
