import cacheAngularTemplate from 'cacheAngularTemplate';
import template from '../views/playlist_map.html';
import angularModule from './playlists_module';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('playlistMap', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const ContentAccessHelper = $injector.get('ContentAccessHelper');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                playlists: '<',
                streams: '<',
                activePlaylist: '<',
                recommendedPlaylist: '<',
                activatePlaylist: '<',
            },
            link(scope) {
                //----------------------------
                // Initialization
                //----------------------------

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                    configurable: true,
                });

                Object.defineProperty(scope, 'relevantCohort', {
                    get() {
                        return scope.currentUser?.relevantCohort;
                    },
                    configurable: true,
                });

                //----------------------------
                // Navigation
                //----------------------------

                scope.selectPlaylist = playlist => {
                    if (ContentAccessHelper.canLaunch(playlist) && scope.activatePlaylist) {
                        scope.scrollToTop();

                        // Note: We changed the binding for activatePlaylist from '&' to '=' and called the method slightly differently.
                        // We did this in order to be able to test. This seems to work just fine, but there are some things we don't
                        // understand here. So, if you change something with this callback be weary of dragons.
                        scope.activatePlaylist(playlist);
                    }
                };

                //----------------------------
                // Display Helpers
                //----------------------------

                scope.scrollToTop = () => {
                    // Scroll to top of the container so the user is not left looking at the list of
                    // streams after selecting a playlist
                    const appMainContainer = document.getElementById('app-main-container');
                    appMainContainer.scrollTop = 0;
                };

                //----------------------------
                // Watches
                //----------------------------

                scope.$watchGroup(
                    [
                        'playlists',
                        'activePlaylist',
                        'recommendedPlaylist',
                        'relevantCohort.id',

                        // Just watching currentUser.pastDueForIdVerification is not totally correct. Theoretically, the results from ContentAccessHelper
                        // could change when a user became past due for id verification in a different program from
                        // the pref one. But, the real fix for this is to refactor the way we check content access such
                        // that it's clear which properties go into it and could change it. Like we do with studentDashboardConfig.
                        'currentUser.pastDueForIdVerification',
                    ],
                    () => {
                        if (!scope.playlists) {
                            return;
                        }

                        const playlists = scope.playlists;
                        const concentrationPlaylists = !scope.relevantCohort
                            ? playlists
                            : scope.relevantCohort.getConcentrationPlaylists(playlists);
                        const concentrationPlaylistsByLocalePackId = _.keyBy(concentrationPlaylists, 'localePackId');
                        const specializationPlaylists = !scope.relevantCohort
                            ? []
                            : scope.relevantCohort.getSpecializationPlaylists(playlists);

                        function createPlaylistInfo(playlist) {
                            return {
                                playlist,
                                active: scope.activePlaylist && playlist.id === scope.activePlaylist.id,
                                recommended: scope.recommendedPlaylist && playlist.id === scope.recommendedPlaylist.id,
                                locked: !ContentAccessHelper.canLaunch(playlist),
                                complete: playlist.complete,
                            };
                        }

                        if (!scope.relevantCohort) {
                            scope.overrideActive = false;
                            scope.playlistCollectionInfos = [
                                {
                                    title: '', // in general, if there's only one playlist collection, we don't need a title
                                    playlistInfos: _.sortBy(playlists, 'title').map(playlist =>
                                        createPlaylistInfo(playlist),
                                    ), // simply use alphabetical order in this case
                                    expanded: true,
                                },
                            ];
                        } else {
                            // create the concentration playlist infos
                            let numUnlockedConcentrationPlaylists = 0;

                            if (scope.relevantCohort) {
                                scope.playlistCollectionInfos = _.map(
                                    scope.relevantCohort.playlist_collections,
                                    playlistCollection => {
                                        const playlistCollectionInfo = {
                                            title: playlistCollection.title,
                                            playlistInfos: _.map(
                                                playlistCollection.required_playlist_pack_ids,
                                                playlistPackId =>
                                                    createPlaylistInfo(
                                                        concentrationPlaylistsByLocalePackId[playlistPackId],
                                                    ),
                                            ),
                                            expanded: _.includes(
                                                playlistCollection.required_playlist_pack_ids,
                                                scope.activePlaylist && scope.activePlaylist.localePackId,
                                            ),
                                        };
                                        numUnlockedConcentrationPlaylists += _.filter(
                                            playlistCollectionInfo.playlistInfos,
                                            playlistInfo => playlistInfo.locked === false,
                                        ).length;
                                        return playlistCollectionInfo;
                                    },
                                );
                            }

                            if (!scope.activePlaylist) {
                                const firstPlaylistCollectionInfo = _.first(scope.playlistCollectionInfos);
                                firstPlaylistCollectionInfo.expanded = true;
                            }

                            // Display message if any of the playlists are locked
                            scope.numUnlockedConcentrationPlaylists = numUnlockedConcentrationPlaylists;
                            scope.overrideActive = !numUnlockedConcentrationPlaylists;
                            scope.hasLockedConcentrationPlaylists =
                                scope.numUnlockedConcentrationPlaylists <
                                _.reduce(
                                    scope.playlistCollectionInfos,
                                    (memo, playlistCollectionInfo) =>
                                        memo + playlistCollectionInfo.playlistInfos.length,
                                    0,
                                );

                            if (!scope.hasLockedConcentrationPlaylists) {
                                //--------------------
                                // Exams
                                //--------------------

                                // In this logic, our main goal is to get the midterm and final exams for the user's relevant cohort.
                                // To do this, first we index all of the streams in the available playlists by their stream locale pack id
                                // and index all of the available streams by their locale pack id. Then we go through each of the required
                                // streams in the relevant cohort schedule and find the exam streams that aren't in the available playlists,
                                // which should leave us with the midterm and final exams (if they exist). It's important to note that this
                                // logic is fairly implicit in the sense that it relies on exam streams to always be required in the schedule
                                // and that the midterm and final exams are not going to be included in the playlists.
                                const playlistStreamsLocalePackIdsMap = {};
                                _.chain(scope.playlists)
                                    .map('streamLocalePackIds')
                                    .flattenDeep()
                                    .uniq()
                                    .forEach(streamLocalePackId => {
                                        playlistStreamsLocalePackIdsMap[streamLocalePackId] = true;
                                    })
                                    .value();
                                const streamsByLocalePackId = _.keyBy(scope.streams, 'localePackId');
                                scope.examsNotIncludedInPlaylists = _.chain(
                                    scope.relevantCohort.getRequiredStreamPackIdsFromPeriods(),
                                )
                                    .map(streamLocalePackId => {
                                        const stream = streamsByLocalePackId[streamLocalePackId];
                                        if (stream.exam && !playlistStreamsLocalePackIdsMap[streamLocalePackId]) {
                                            return stream;
                                        }
                                        return undefined;
                                    })
                                    .compact()
                                    .value();
                            }

                            //--------------------
                            // Specializations
                            //--------------------

                            if (specializationPlaylists.length > 0) {
                                scope.specializationPlaylistInfos = _.sortBy(specializationPlaylists, playlist => {
                                    let index = -1;
                                    if (scope.relevantCohort && scope.relevantCohort.specialization_playlist_pack_ids) {
                                        index = scope.relevantCohort.specialization_playlist_pack_ids.indexOf(
                                            playlist.localePackId,
                                        );
                                    }
                                    return index > -1 ? index : 9999;
                                }).map(playlist => createPlaylistInfo(playlist));

                                scope.numRequiredSpecializations = scope.relevantCohort.num_required_specializations;
                                scope.numSpecializationsComplete = _.filter(
                                    scope.specializationPlaylistInfos,
                                    playlistInfo => playlistInfo.complete,
                                ).length;
                            }

                            // setup the locked message if you have any locked required playlists
                            if (scope.hasLockedConcentrationPlaylists) {
                                scope.playlistsLockedMessageKey = ContentAccessHelper.playlistsLockedReasonKey(
                                    scope.currentUser,
                                );
                            } else {
                                scope.playlistsLockedMessageKey = undefined;
                            }

                            scope.registrarEmail = scope.relevantCohort?.supportEmailAddress('registrar');
                        }
                    },
                );
            },
        };
    },
]);
