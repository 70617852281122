import { isEqual } from 'lodash/fp';
import { memo, type FC } from 'react';
import { type CoreConcept } from 'StreamLessonTutorBotRecord';
import { type CoverageMaps } from './CoverageMaps.types';
import { FrameLinkList } from './FrameLinkList';

type Props = {
    coreConcept: CoreConcept;
    coverageMaps: CoverageMaps;
};

export const ShowCoreConceptCoverage: FC<Props> = memo(({ coreConcept, coverageMaps }) => {
    const entry = coverageMaps.coreConcept[coreConcept.id];

    return (
        <div className="mx-4 mb-4 rounded-5 border border-beige-medium bg-gray-50 p-4">
            <p className="mb-4">
                <strong className="font-semibold">Core Concept: {coreConcept.title}</strong>
            </p>
            <div>
                <strong className="font-semibold">Learned in Lesson</strong>
                <ul className="mb-4 ms-5 mt-1 list-disc">
                    <li>
                        <FrameLinkList frameEntries={entry.learningFrames} />
                    </li>
                </ul>
            </div>
            <div>
                <strong className="font-semibold">Tested in Exam</strong>
                {entry.examFrames.length === 0 && (
                    <p className="mb-4 ms-5 mt-1 italic text-gray-700">No exam frames found.</p>
                )}
                {entry.examFrames.length > 0 && (
                    <ul className="mb-4 ms-5 mt-1 list-disc">
                        <li>
                            <FrameLinkList frameEntries={entry.examFrames} />
                        </li>
                    </ul>
                )}
            </div>
        </div>
    );
}, isEqual);
