// This file exists to hold and overwrite a bunch of base styling
// that are set for the the popover's arrow in heroui

// I am putting more classes here than necessary to make it easier to
// see how things work and update in the future if we want.

// This list is a modified version of the classes found at
// github.com/heroui-inc/heroui/blob/canary/packages/core/theme/src/components/popover.ts

export const arrowClasses = [
    'before:w-3',
    'before:h-3',
    'before:rounded-none',
    'before:z-1',
    'before:border-none',
    'before:border-solid',
    'before:shadow-none',

    // top
    'data-[placement=top]:before:border-t-0',
    'data-[placement=top]:before:border-l-0',
    'data-[placement=top]:before:-bottom-[calc(theme(spacing.5)/4_+_0.5px)]',
    'data-[placement=top]:before:left-1/2',
    'data-[placement=top]:before:-translate-x-1/2',
    'data-[placement=top-start]:before:border-t-0',
    'data-[placement=top-start]:before:border-l-0',
    'data-[placement=top-start]:before:-bottom-[calc(theme(spacing.5)/4_+_0.5px)]',
    'data-[placement=top-start]:before:left-4',
    'data-[placement=top-end]:before:-bottom-[calc(theme(spacing.5)/4_+_0.5px)]',
    'data-[placement=top-end]:before:right-4',
    'data-[placement=top-end]:before:border-t-0',
    'data-[placement=top-end]:before:border-l-0',
    // bottom
    'data-[placement=bottom]:before:border-r-0',
    'data-[placement=bottom]:before:border-b-0',
    'data-[placement=bottom]:before:-top-[calc(theme(spacing.5)/4_+_0.5px)]',
    'data-[placement=bottom]:before:left-1/2',
    'data-[placement=bottom]:before:-translate-x-1/2',
    'data-[placement=bottom-start]:before:-top-[calc(theme(spacing.5)/4_+_1px)]',
    'data-[placement=bottom-start]:before:left-4',
    'data-[placement=bottom-start]:before:border-r-0',
    'data-[placement=bottom-start]:before:border-b-0',
    'data-[placement=bottom-end]:before:-top-[calc(theme(spacing.5)/4_+_0.5px)]',
    'data-[placement=bottom-end]:before:right-4',
    'data-[placement=bottom-end]:before:border-r-0',
    'data-[placement=bottom-end]:before:border-b-0',
    // left
    'data-[placement=left]:before:border-l-0',
    'data-[placement=left]:before:border-b-0',
    'data-[placement=left]:before:-right-[calc(theme(spacing.5)/4_-_9px)]',
    'data-[placement=left]:before:top-1/2',
    'data-[placement=left]:before:-translate-y-1/2',
    'data-[placement=left-start]:before:border-l-0',
    'data-[placement=left-start]:before:border-b-0',
    'data-[placement=left-start]:before:-right-[calc(theme(spacing.5)/4_-_9px)]',
    'data-[placement=left-start]:before:top-1/4',
    'data-[placement=left-end]:before:border-l-0',
    'data-[placement=left-end]:before:border-b-0',
    'data-[placement=left-end]:before:-right-[calc(theme(spacing.5)/4_-_9px)]',
    'data-[placement=left-end]:before:bottom-1/4',
    // right
    'data-[placement=right]:before:border-r-0',
    'data-[placement=right]:before:border-t-0',
    'data-[placement=right]:before:-left-[calc(theme(spacing.5)/4_+0.5px)]',
    'data-[placement=right]:before:top-1/2',
    'data-[placement=right]:before:-translate-y-1/2',
    'data-[placement=right-start]:before:border-r-0',
    'data-[placement=right-start]:before:border-t-0',
    'data-[placement=right-start]:before:-left-[calc(theme(spacing.5)/4_+0.5px)]',
    'data-[placement=right-start]:before:top-1/4',
    'data-[placement=right-end]:before:-left-[calc(theme(spacing.5)/4_+0.5px)]',
    'data-[placement=right-end]:before:bottom-1/4',
    'data-[placement=right-end]:before:border-r-0',
    'data-[placement=right-end]:before:border-t-0',
];
