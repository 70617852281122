import angularModule from 'Navigation/angularModule/scripts/navigation_module';
import { setupBrandNameProperties, setupBrandScopeProperties, setupStyleHelpers } from 'AppBranding';
import template from 'Navigation/angularModule/views/app_menu_mobile.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { AI_ADVISOR_BRAND_NAME } from 'TutorBotAiAdvisorChat';

import menuSearchWhite from 'images/menu_search_white.png';
import menuWorkWhite from 'images/menu_work_white.png';
import menuSettingsWhite from 'images/menu_settings_white.png';
import menuSpeechBubbleWhite from 'images/menu_speech_bubble_white.png';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('appMenuMobile', [
    '$injector',
    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const $location = $injector.get('$location');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const ClientStorage = $injector.get('ClientStorage');
        const offlineModeManager = $injector.get('offlineModeManager');

        return {
            restrict: 'E',
            scope: {},
            templateUrl,
            link(scope) {
                NavigationHelperMixin.onLink(scope);

                scope.menuSearchWhite = menuSearchWhite;
                scope.menuWorkWhite = menuWorkWhite;
                scope.menuSettingsWhite = menuSettingsWhite;
                scope.menuSpeechBubbleWhite = menuSpeechBubbleWhite;
                scope.AI_ADVISOR_BRAND_NAME = AI_ADVISOR_BRAND_NAME;

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                setupStyleHelpers($injector, scope);
                setupBrandNameProperties($injector, scope);
                setupBrandScopeProperties($injector, scope, ['appMenuMobileLogo']);

                scope.viewModel = AppHeaderViewModel;
                scope.offlineModeManager = offlineModeManager;

                // This has to be a getter/setter so it doesn't get
                // eaten up by the ng-if
                Object.defineProperty(scope, 'tourStep', {
                    get() {
                        return scope.$tourStep;
                    },
                    set(val) {
                        scope.$tourStep = val;
                    },
                });

                Object.defineProperty(scope, 'applicationOrAiAdvisor', {
                    get() {
                        // Because of space limitations, we only want to show the application link OR
                        // the AI Advisor link, not both.
                        if (scope.currentUser?.canCurrentlySubmitProgramApplication) return 'application';
                        if (scope.currentUser?.hasBotPageAccess) return 'ai-advisor';
                        return null;
                    },
                });

                //-----------------
                // Active tab logic
                //-----------------

                // When initializing set the active tab based on location
                scope.activeTab = setActiveTab();

                // If in an inner screen see which tab was active last
                if (!scope.activeTab) {
                    scope.activeTab = ClientStorage.getItem('activeTab');
                }

                // When changing routes try to set the new active tab
                $rootScope.$on('$routeChangeSuccess', () => {
                    setActiveTab();
                });

                function setActiveTab() {
                    if ($location.path().startsWith('/dashboard')) {
                        scope.activeTab = 'dashboard';
                    } else if ($location.path().startsWith('/courses')) {
                        scope.activeTab = 'courses';
                    } else if ($location.path().startsWith('/careers')) {
                        scope.activeTab = 'careers';
                    } else if ($location.path().startsWith('/student-network')) {
                        scope.activeTab = 'student-network';
                    } else if ($location.path().startsWith('/settings/application')) {
                        scope.activeTab = 'application';
                    } else if ($location.path().startsWith('/resources')) {
                        scope.activeTab = 'resources';
                    } else if ($location.path().startsWith('/bot')) {
                        scope.activeTab = 'ai-advisor';
                    }

                    if (scope.activeTab) {
                        ClientStorage.setItem('activeTab', scope.activeTab);
                    }
                }
            },
        };
    },
]);
